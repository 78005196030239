/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap
*/

/* --------------------------------------------------------------------------------------------- */

.pagination {
  margin: 0px auto;
  width: fit-content;
}
.pagination button {
  width: 24px;
  height: 24px;
  min-width: unset;
  min-height: unset;
  border-radius: 54px;
  margin: 0 4px;
  padding: 0;
  color: #555;
}
.pagination button.active {
  background: #2196f3;
  color: white;
}

/* --------------------------------------------------------------------------------------------- */

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .pagination {
    margin: 20px auto;
  }
  .pagination button {
    width: 32px;
    height: 32px;
    margin: 0 8px;
    padding: unset;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
