/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap
*/


/* --------------------------------------------------------------------------------------------- */
.services ul {
  font-size: 1rem;
  list-style: none;
  padding-left: 1.5rem;
}

.services li {
  padding-bottom: 0.625rem;
}

.services h2 {
  padding: 1.25rem 1.25rem 0.313rem 1.5rem;
  margin-bottom: 1.25rem !important;
  border-bottom: solid 1px #cc0000;
}
  
/* --------------------------------------------------------------------------------------------- */


/*Small devices (landscape phones, 576px and up)*/

@media (min-width: 576px) {

}


/*Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) {

}


/*Large devices (desktops, 992px and up)*/

@media (min-width: 992px) {

}


/* Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1200px) {

}
